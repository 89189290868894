/**
 * Generic React Components
 *
 * @module
 *
 */

import React from 'react';
import ReactDOM from 'react-dom';

import { f } from '../functools';
import { spanNumber } from '../helpers';
import { getIcon } from '../icons';
import { commonText } from '../localization/common';
import { getModel } from '../schema';
import { Button, className, Textarea } from './basic';
import { copyTextToClipboard } from './filepicker';
import { useBooleanState, useTitle } from './hooks';
import { icons } from './icons';
import { compareStrings } from './internationalization';
import { usePref } from './preferenceshooks';
import { RA } from '../types';

const MAX_HUE = 360;

/**
 * Convert first 2 characters of a table name to a number [0,255] corresponding
 * to color hue.
 *
 * Used for autogenerated table icons if table icon image is missing.
 */
const getHue = spanNumber(
  // eslint-disable-next-line unicorn/prefer-code-point
  'a'.charCodeAt(0) * 2,
  // eslint-disable-next-line unicorn/prefer-code-point
  'z'.charCodeAt(0) * 2,
  0,
  MAX_HUE
);

/** Generate an HSL color based on the first 2 characters of a string */
export const stringToColor = (name: string) =>
  f.var(
    name.toLowerCase(),
    (name) =>
      `hsl(${getHue(
        // eslint-disable-next-line unicorn/prefer-code-point
        (name[0] ?? 'a').charCodeAt(0) + (name[1] ?? 'a').charCodeAt(0)
      )}, 70%, 50%)`
  );

/**
 * Renders a table icon or autogenerates a new one
 */
export function TableIcon({
  name,
  tableLabel,
  /*
   * It is highly recommended to use the same icon size everywhere, as that
   * improves consistency, thus, this should be overwritten only if it is
   * strictly necessary.
   */
  className = 'w-table-icon h-table-icon',
}: {
  readonly name: string;
  readonly tableLabel?: string | false;
  readonly className?: string;
}): JSX.Element {
  const tableIconSource = getIcon(name);
  const resolvedTableLabel =
    tableLabel === false
      ? undefined
      : tableLabel ?? getModel(name)?.label ?? '';
  const role = typeof resolvedTableLabel === 'string' ? 'img' : undefined;
  const ariaHidden = typeof resolvedTableLabel === 'undefined';
  if (typeof tableIconSource === 'string')
    return (
      <span
        className={`${className} bg-center bg-no-repeat bg-contain`}
        role={role}
        style={{ backgroundImage: `url('${tableIconSource}')` }}
        title={resolvedTableLabel}
        aria-label={resolvedTableLabel}
        aria-hidden={ariaHidden}
      />
    );

  // If icon is missing, show an autogenerated one:
  return (
    <span
      style={{ backgroundColor: stringToColor(name) }}
      role={role}
      className={`w-table-icon h-table-icon flex items-center justify-center
        text-white rounded-sm text-sm`}
      title={resolvedTableLabel}
      aria-label={resolvedTableLabel}
      aria-hidden={ariaHidden}
    >
      {name.slice(0, 2).toUpperCase()}
    </span>
  );
}

export const tableIconUndefined = (
  <span
    className="w-table-icon h-table-icon flex items-center justify-center font-bold text-red-600"
    aria-label={commonText('unmapped')}
    role="img"
  >
    {icons.ban}
  </span>
);

export const tableIconSelected = (
  <span
    className="w-table-icon h-table-icon flex items-center justify-center font-bold text-green-500"
    aria-label={commonText('mapped')}
    role="img"
  >
    {icons.check}
  </span>
);

export const tableIconEmpty = (
  <span className="w-table-icon h-table-icon" aria-hidden={true} />
);

/** Internationalized bi-directional string comparison function */
export const compareValues = (
  ascending: boolean,
  valueLeft: string | undefined,
  valueRight: string | undefined
): number =>
  compareStrings(valueLeft ?? '', valueRight ?? '') * (ascending ? -1 : 1);

export type SortConfig<FIELD_NAMES extends string> = {
  readonly sortField: FIELD_NAMES;
  readonly ascending: boolean;
};

export function SortIndicator<FIELD_NAMES extends string>({
  fieldName,
  sortConfig,
}: {
  readonly fieldName: string;
  readonly sortConfig: SortConfig<FIELD_NAMES>;
}): JSX.Element {
  const isSorted = sortConfig.sortField === fieldName;
  return (
    <span
      className="text-brand-300"
      aria-label={
        isSorted
          ? sortConfig.ascending
            ? commonText('ascending')
            : commonText('descending')
          : undefined
      }
    >
      {isSorted
        ? sortConfig.ascending
          ? icons.chevronUp
          : icons.chevronDown
        : undefined}
    </span>
  );
}

/**
 * A React Portal wrapper
 *
 * @remarks
 * Based on https://blog.logrocket.com/learn-react-portals-by-example/
 *
 * Used when an elements needs to be renreded outside of the bounds of
 * the container that has overflow:hidden
 */
export function Portal({
  children,
}: {
  readonly children: JSX.Element;
}): JSX.Element {
  const element = React.useMemo(() => document.createElement('div'), []);

  React.useEffect(() => {
    const portalRoot = document.getElementById('portal-root');
    if (portalRoot === null) throw new Error('Portal root was not found');
    portalRoot.append(element);
    return (): void => element.remove();
  }, [element]);

  return ReactDOM.createPortal(children, element);
}

export function AppTitle({
  title,
  type,
}: {
  readonly title: string;
  readonly type?: 'form';
}): null {
  const [updateTitle] = usePref('form', 'behavior', 'updatePageTitle');
  useTitle(type !== 'form' || updateTitle ? title : undefined);
  return null;
}

export function AutoGrowTextArea({
  containerClassName,
  ...props
}: Parameters<typeof Textarea>[0] & {
  readonly containerClassName?: string;
}): JSX.Element {
  const [textArea, setTextArea] = React.useState<HTMLTextAreaElement | null>(
    null
  );
  const [shadow, setShadow] = React.useState<HTMLDivElement | null>(null);
  /*
   * If user manually resized the textarea, need to keep the shadow in sync
   * Fixes https://github.com/specify/specify7/issues/1783
   * Can't simply convert auto growing textarea into a regular one on the fly
   * because that interrupts the resize operation
   */
  React.useEffect(() => {
    if (textArea === null || shadow === null) return undefined;
    const observer = new ResizeObserver(() => {
      shadow.style.height = textArea.style.height;
      shadow.style.width = textArea.style.width;
    });
    observer.observe(textArea);
    return (): void => observer.disconnect();
  }, [textArea, shadow]);
  return (
    <div
      className={`
        relative min-h-[calc(theme(spacing.7)*var(--rows))] overflow-hidden
        ${containerClassName ?? ''}
      `}
      style={{ '--rows': props.rows ?? 3 } as React.CSSProperties}
    >
      {/*
       * Shadow a textarea with a div, allowing it to autoGrow. Source:
       * https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
       */}
      <div
        className={`
          textarea-shadow print:hidden invisible whitespace-pre-wrap
          [grid-area:1/1/2/2] ${className.textArea}
        `}
        ref={setShadow}
      >
        {`${props.value?.toString() ?? ''} `}
      </div>
      <Textarea
        {...props}
        className={`
          h-full top-0 [grid-area:1/1/2/2] absolute
          ${props.className ?? ''}
        `}
        forwardRef={(textArea): void => {
          setTextArea(textArea);
          if (typeof props.forwardRef === 'function')
            props.forwardRef(textArea);
          else if (
            typeof props.forwardRef === 'object' &&
            props.forwardRef !== null &&
            'current' in props.forwardRef
          )
            /* TODO: improve typing to make this editable */
            // @ts-expect-error Modifying a read-only property
            props.forwardRef.current = textArea;
        }}
      />
    </div>
  );
}

/**
 * Add a JSX.Element in between JSX.Elements.
 *
 * Can't use .join() because it only works with strings.
 */
export const join = (
  // Don't need to add a [key] prop to these elements before passing in
  elements: RA<JSX.Element>,
  separator: JSX.Element
): RA<JSX.Element> =>
  elements.map((element, index, { length }) => (
    <React.Fragment key={index}>
      {element}
      {index + 1 === length ? undefined : separator}
    </React.Fragment>
  ));

const copyMessageTimeout = 3000;

export function CopyButton({
  text,
  label = commonText('copyToClipboard'),
}: {
  readonly text: string;
  readonly label?: string;
}): JSX.Element {
  const [wasCopied, handleCopied, handleNotCopied] = useBooleanState();
  return (
    <Button.Green
      className="whitespace-nowrap"
      onClick={(): void =>
        void copyTextToClipboard(text).then((): void => {
          handleCopied();
          setTimeout(handleNotCopied, copyMessageTimeout);
        })
      }
    >
      {wasCopied ? commonText('copied') : label}
    </Button.Green>
  );
}
